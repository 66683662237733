import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import SmartphonePix from "../../../../assets/images/svg/smartphone-pix.svg"
import { isMobile } from "../../../../helpers"

const Card = styled.div`
  display: flex;
  width: ${isMobile() ? 300 : 380}px;
  height: 532px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
`

const Text = styled.p`
  display: flex;
  width: 323px;
  flex-direction: column;
  justify-content: center;
  z-index: 1;

  color: var(--color-neutral-3, #1d77f1);
  font-size: 20px;
  font-family: Poppins;
  font-weight: bold;
  line-height: 2px;
`

const Time = styled.div`
  z-index: 1;
  display: flex;
  width: 254px;
  height: 79px;
  justify-content: center;
  align-items: center;

  margin-top: 18px;
  background: #e7efff;
  border-radius: 12px;
`

const TextTime = styled.span`
  display: flex;
  height: 74px;
  align-items: center;
  width: 100px;
  color: #1d77f1;
  text-align: center;
  font-size: 36px;
  font-family: Poppins;
  font-weight: 700;
`

const Content = styled.div`
  display: flex;
  z-index: 1;
  text-align: center;
  margin-bottom: ${isMobile() ? "55" : "55"}px;
  margin-top: ${isMobile() ? "28" : "55"}px;
`

const infoText = styled.span`
  color: red;
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 28px;

  width: 200px;
  flex-direction: column;
  justify-content: center;
  z-index: 12;
`
function calculateTimeLeft(createDate) {
  const timePassed = Math.floor(
    (new Date().getTime() - new Date(createDate).getTime()) / 1000
  )
  const timeLeft = 10 * 60 - timePassed
  return timeLeft > 0 ? timeLeft : 0
}

const TimePix = ({
  createDate,
  setIsExpired,
  amount,
  pixValue,
  visible,
  getPixById,
  translation,
  isFetching,
}) => {
  const [remainingSeconds, setRemainingSeconds] = useState(() =>
    calculateTimeLeft(createDate)
  )
  const [resetTime, setResetTime] = useState(true)
  const [timeToCheckPix, setTimeToCheckPix] = useState()

  useEffect(() => {
    if (createDate) {
      setTimeToCheckPix(calculateTimeLeft(createDate) - 15)
    }

    const lastTimeToCheck = (calculateTimeLeft(createDate) + 15) * 1000

    setTimeout(() => {}, lastTimeToCheck)
  }, [createDate])

  if (remainingSeconds <= timeToCheckPix) {
    getPixById()
    setTimeToCheckPix(state => state - 15)
  }

  useEffect(() => {
    setRemainingSeconds(calculateTimeLeft(createDate))
  }, [createDate])

  useEffect(() => {
    if (remainingSeconds <= 0) {
      setIsExpired(true)
      return
    }

    const timerId = setTimeout(() => {
      setRemainingSeconds(remainingSeconds - 1)
    }, 1000)

    return () => clearTimeout(timerId)
  }, [remainingSeconds])

  const minutes = Math.floor(remainingSeconds / 60)
  const seconds = remainingSeconds % 60

  useEffect(() => {
    if (amount === pixValue) {
      setResetTime(true)
    } else {
      setResetTime(false)
    }
  }, [pixValue])

  return (
    <Card>
      <SmartphonePix
        style={{
          position: "absolute",
          zIndex: 0,
          height: 532,
          width: isMobile() ? 370 : 421,
          borderTopLeftRadius: 70,
          borderTopRightRadius: 70,
        }}
      />
      {visible && (
        <>
          {remainingSeconds > 0 ? (
            <>
              <Text>{translation.awaitingPayment}</Text>
              <Content>
                <infoText
                  style={{
                    fontSize: 14,
                    whiteSpace: "pre-wrap",
                    lineHeight: "28px",
                  }}
                >
                  {translation.pixCodeExpiryNote}
                </infoText>
              </Content>
              <Time>
                <TextTime>
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </TextTime>
              </Time>
            </>
          ) : (
            <>
              <Text>{translation.copyPixCode}</Text>
              <Content>
                <infoText
                  style={{
                    fontSize: 16,
                    whiteSpace: "pre-wrap",
                    lineHeight: "28px",
                  }}
                >
                  {translation.generateNewPixCode}
                </infoText>
              </Content>
            </>
          )}
        </>
      )}
    </Card>
  )
}

export default TimePix
